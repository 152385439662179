import { track } from '@/services/xray';
import { lsRetrieve } from '@/helpers/localStorage';

const AI_TYPES = ['sab', 'sppo', 'smartPersonalizer', 'smartPopup'];
const PERSONALIZATION_MODAL_MAP = {
  personalizer: 'page',
};
const PERSONALIZATION_MODAL_TYPES = ['abTest', 'personalizer', 'multiCampaignABTest'];

export const OPTIMIZATION_TYPES = ['abTest', 'sab', 'sppo', 'smartPopup', 'multiCampaignABTest'];

export default {
  components: {
    MoreDetails: () => import('@/components/Modals/NewCampaignFlow/MoreDetails.vue'),
    Book: () => import('@/components/Modals/NewCampaignFlow/Book.vue'),
    DcPersonalization: () =>
      import('@/components/TemplateChooser/components/modals/Personalization.vue'),
    NewCampaign: () => import('@/components/Modals/NewCampaign.vue'),
    LastRequestDateOld: () => import('@/components/Modals/LastRequestDateOld.vue'),
  },
  data: () => ({
    about: null,
    aiTypes: AI_TYPES,
    HAS_SEEN_MULTI_CAMPAIGN_MODAL_KEY: 'hasSeenMultiCampaignModal',
  }),
  computed: {
    accountId() {
      return this.$route.params.userId;
    },
  },
  methods: {
    getHasSeenMultiCampaignModal() {
      const hasSeenMultiCampaignModal = lsRetrieve(this.HAS_SEEN_MULTI_CAMPAIGN_MODAL_KEY);
      return hasSeenMultiCampaignModal?.[this.accountId] || false;
    },
    openDetailsModal() {
      if (!this.about) return;
      const isDC = PERSONALIZATION_MODAL_TYPES.includes(this.about);
      const modalName = isDC ? 'dc-personalization' : 'new-campaign-flow-more-details';

      if (this.about === 'multiCampaignABTest') {
        const doNotShowModalAgain = this.getHasSeenMultiCampaignModal();
        if (doNotShowModalAgain) {
          this.$router.push({ name: 'experiments', query: { startFlow: true } });
          return;
        }
      }

      this.$modal.show(modalName, {
        type: PERSONALIZATION_MODAL_MAP[this.about] ?? this.about,
      });
    },
    onTrack({ name, properties }) {
      track(`newCampaignFlow-${name}`, properties);
    },
    onDeselect() {
      this.about = null;
    },
    getOptimizationType(value) {
      return OPTIMIZATION_TYPES.includes(value) ? 'optimize' : 'personalize';
    },
  },
};
